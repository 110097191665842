import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = ({ data, location }) => {
  const siteTitle = 'About Me'
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle} pageInfo={{ pageName: "about" }}>
      <SEO title="About Me" />
      <Bio />
      <p>
      I’m <strong>Krishna Halaharvi</strong>, an Engineering Leader with years of experience 
      working on web applications at scale. I’ve been part of the larger tech community since 
      2012, and have brought lean engineering principles to teams and transformed company 
      culture by introducing containerization practices, improving code maturity of javascript, 
      adhering to good API design, and managing technical debt. I believe teams work best when 
      they have agency over what they create.
      </p>
      <p>
      My specialties are Javascript, engineering management, cloud infrastructure, 
      containers, and deployment pipelines. I’m great at taking existing platforms 
      and scaling them up to be more reliable, secure, and performant. I love 
      digging into obscure software logic and simplifying workflows. I’ve 
      led engineering teams with efficiency and empathy. Ensuring that my team has a strong 
      sense of ownership and autonomy to find well engineered solutions.  
      </p>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
